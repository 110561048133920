import _cloneDeep from "lodash/cloneDeep";
import { BREAKPOINTS, LAYOUT_COLUMNS } from "../constants";
import { WidgetConfigLayout, WidgetLayout, WidgetsLayout } from "../types";

export const calculateWidgetLayout = (pageLayout: WidgetsLayout, widgetLayout: WidgetConfigLayout, id: string) => {
  const resultLayout = _cloneDeep(widgetLayout);

  const space: Record<string, Array<Array<boolean>>> = {};

  Object.keys(BREAKPOINTS).forEach((bp) => {
    const bpSpace: Array<Array<boolean>> = [];

    Object.values(pageLayout).forEach(({ layout }) => {
      const { w, x, h, y } = layout[bp] || {};

      for (let i = y; i < y + h; i++) {
        if (!bpSpace[i]) {
          bpSpace[i] = new Array(LAYOUT_COLUMNS).fill(true);
        }
        for (let j = x; j < x + w; j++) {
          bpSpace[i][j] = false;
        }
      }
    });

    space[bp] = bpSpace;
  });

  Object.keys(widgetLayout).forEach((bp) => {
    const mx = space[bp];
    const layout = widgetLayout[bp];
    const widgetWidth = layout.w;
    const widgetMinWidth = layout.minW;
    const targetWidth = widgetMinWidth || widgetWidth;

    if (!mx) return;

    let freeX = -1;
    let freeY = -1;
    let freeWidth = 0;

    rowsLoop: for (let i = 0; i < mx.length; i++) {
      let start = -1;
      let width = 0;

      for (let j = 0; j < mx[i].length + 1; j++) {
        if (mx[i][j]) {
          if (start < 0) start = j;
          width++;
        } else {
          if (width >= targetWidth) {
            freeX = start;
            freeY = i;
            freeWidth = width;
            break rowsLoop;
          }
          width = 0;
          start = -1;
        }
      }
    }

    resultLayout[bp] = {
      i: id,
      ...resultLayout[bp],
      x: freeX < 0 ? 0 : freeX,
      y: freeY < 0 ? mx.length : freeY,
      w: freeWidth && layout.w > freeWidth ? freeWidth : layout.w,
    };
  });

  return resultLayout as WidgetLayout;
};
