import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { MAIN_ACCOUNT_VALUE } from "@src/pages/cefi/constants";
import { Position } from "@src/pages/cefi/positions-table/types";
import { CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";
import { PortfolioMessage, PortfolioMsgData } from "@src/subscriptions/types";

export const DEFAULT_CEFI_ALLOCATION_ITEM_ACTIVE = "main";
export const DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE = "All chains";
export const DEFAULT_TOTAL_BALANCE = 0;
export const SELECT_ALL = "all";

export enum PortfolioType {
  CEFI = "cefi",
  DEFI = "defi",
}

export enum ViewType {
  FUNDS = "funds",
  TRANSACTION = "transactions",
}

export interface AllocationAccount {
  subaccount: string;
  value: number;
  percent: number;
  iconAddress?: string;
}

export interface WalletAllocation {
  walletAddress: string;
  value: number;
}

export interface AllocationToken {
  tokenName: string;
  value: number;
  percent?: number;
  iconAddress?: string;
}

// exchangeId['MAIN_ACCOUNT' | 'subaccount']['spot' | 'future']
export type TCefiStreamData = Record<string, Record<CefiExchangeMarketType, PortfolioMsgData["data"]>>;
export type TCefiStreamFlatData = Record<string, Position>;
export interface Portfolio {
  stream: {
    assets: Record<string, TCefiStreamData>;
    positions: TCefiStreamFlatData;
  };
  activeCefiAllocationItem: string;
  activeDefiAllocationItem: string;
  dataUpdatedCefi: string | null;
  dataUpdatedDefi: string | null;
  selectedExchange: string;
  selectedWallet: string;
  selectedExchanges: string[];
  cefiTotalBalance: number;
  defiTotalBalance: number;
  overviewCefiTotalBalance: number;
  overviewDefiTotalBalance: number;
  accountDefiAllocation: AllocationAccount[];
  accountCefiAllocation: AllocationAccount[];
  tokenDefiAllocation: AllocationToken[];
  tokenCefiAllocation: AllocationToken[];
  walletAllocation: WalletAllocation[];
  marketType: CefiExchangeMarketType;
  tableDataIsLoading: boolean;
  exchangeIsLoading: boolean;
  userExchangesListLoading: boolean;
}

export const getPortfolioAssetMsgId = (
  exchange: string,
  subaccount: string | null,
  asset_class: string,
  symbol: string
) => [exchange, subaccount || MAIN_ACCOUNT_VALUE, asset_class, symbol].join("~");

export const parsePortfolioAssetMsgId = (id: string) => {
  const [exchange, subaccount, asset_class, symbol] = id.split("~");

  return {
    exchange,
    subaccount,
    assetClass: asset_class,
    symbol,
  };
};

const initialState: Portfolio = {
  stream: {
    assets: {},
    positions: {},
  } as Portfolio["stream"],
  activeCefiAllocationItem: DEFAULT_CEFI_ALLOCATION_ITEM_ACTIVE,
  activeDefiAllocationItem: DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE,
  dataUpdatedCefi: null,
  dataUpdatedDefi: null,
  selectedExchange: SELECT_ALL,
  selectedWallet: SELECT_ALL,
  selectedExchanges: [],
  cefiTotalBalance: DEFAULT_TOTAL_BALANCE,
  defiTotalBalance: DEFAULT_TOTAL_BALANCE,
  overviewCefiTotalBalance: DEFAULT_TOTAL_BALANCE,
  overviewDefiTotalBalance: DEFAULT_TOTAL_BALANCE,
  accountDefiAllocation: [],
  accountCefiAllocation: [],
  tokenDefiAllocation: [],
  tokenCefiAllocation: [],
  walletAllocation: [],
  marketType: CefiExchangeMarketType.SPOT,
  tableDataIsLoading: false,
  exchangeIsLoading: false,
  userExchangesListLoading: false,
};

export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setTableDataIsLoading: (state, action: PayloadAction<boolean>) => {
      state.tableDataIsLoading = action.payload;
    },
    setExchangeIsLoading: (state, action: PayloadAction<boolean>) => {
      state.exchangeIsLoading = action.payload;
    },
    setUserExchangesListLoading: (state, action: PayloadAction<boolean>) => {
      state.userExchangesListLoading = action.payload;
    },
    setActiveCefiAllocationItem: (state, action: PayloadAction<string>) => {
      state.activeCefiAllocationItem = action.payload;
    },
    setActiveDefiAllocationItem: (state, action: PayloadAction<string>) => {
      state.activeDefiAllocationItem = action.payload;
    },
    setSelectedExchange: (state, action: PayloadAction<string>) => {
      state.activeCefiAllocationItem = DEFAULT_CEFI_ALLOCATION_ITEM_ACTIVE;
      state.selectedExchange = action.payload;
    },
    setSelectedWallet: (state, action: PayloadAction<string>) => {
      state.activeDefiAllocationItem = DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE;
      state.selectedWallet = action.payload;
    },
    setCefiTotalBalance: (state, action: PayloadAction<number>) => {
      state.cefiTotalBalance = action.payload;

      if (state.selectedExchange === SELECT_ALL) state.overviewCefiTotalBalance = action.payload;
    },
    setDefiTotalBalance: (state, action: PayloadAction<number>) => {
      state.defiTotalBalance = action.payload;

      if (state.selectedWallet === SELECT_ALL) state.overviewDefiTotalBalance = action.payload;
    },
    setAccountDefiAllocation: (state, action: PayloadAction<AllocationAccount[]>) => {
      state.accountDefiAllocation = [...action.payload];
    },
    setAccountCefiAllocation: (state, action: PayloadAction<AllocationAccount[]>) => {
      state.accountCefiAllocation = [...action.payload];
    },
    setTokenDefiAllocation: (state, action: PayloadAction<AllocationToken[]>) => {
      state.tokenDefiAllocation = [...action.payload];
    },
    setTokenCefiAllocation: (state, action: PayloadAction<AllocationToken[]>) => {
      state.tokenCefiAllocation = [...action.payload];
    },
    setWalletAllocation: (state, action: PayloadAction<WalletAllocation[]>) => {
      state.walletAllocation = [...action.payload];
    },
    setDataUpdatedCefi: (state, action: PayloadAction<string>) => {
      state.dataUpdatedCefi = action.payload;
    },
    setDataUpdatedDefi: (state, action: PayloadAction<string>) => {
      state.dataUpdatedDefi = action.payload;
    },
    setCefiExchangeMarketType: (state, action: PayloadAction<CefiExchangeMarketType>) => {
      state.marketType = action.payload;
    },
    setCefiStreamData: (state, action: PayloadAction<PortfolioMessage>) => {
      if ("init_data" in action.payload) {
        const data = action.payload.init_data;

        const positions = data.reduce((res, asset) => {
          const {
            balance,
            entry_price,
            exchange_id,
            liquidation_price,
            notional,
            price,
            subaccount,
            token_name,
            unrealized_pnl,
            leverage,
            margin_mode,
            is_position,
          } = asset;

          const asset_class = CefiExchangeMarketType.FUTURE;
          const exchange = exchange_id;
          const _subaccount = subaccount || MAIN_ACCOUNT_VALUE;
          const symbol = token_name;

          const id = getPortfolioAssetMsgId(exchange, _subaccount, asset_class, symbol);

          return {
            ...res,
            [id]: {
              symbol,
              exchange,
              asset_class,
              subaccount: _subaccount,
              balance,
              price,
              entryPrice: entry_price,
              liquidationPrice: liquidation_price,
              notional,
              unrealizedPnl: unrealized_pnl,
              leverage: leverage || 1,
              margin_mode,
              isPosition: is_position,
            },
          };
        }, {});

        Object.assign(state.stream.positions, positions);
      } else {
        const { exchange, asset_class, subaccount, data } = action.payload.message || {};
        const _subaccount = subaccount || MAIN_ACCOUNT_VALUE;

        if (!(exchange && asset_class && data)) return state;

        if (!state.stream.assets[exchange]) state.stream.assets[exchange] = {};

        if (!state.stream.assets[exchange][_subaccount]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          state.stream.assets[exchange][_subaccount] = {};
        }

        if (!state.stream.assets[exchange][_subaccount][asset_class]) {
          state.stream.assets[exchange][_subaccount][asset_class] = data;
        } else {
          state.stream.assets[exchange][_subaccount][asset_class] = {
            ...current(state.stream.assets[exchange][_subaccount][asset_class]),
            ...data,
          };
        }

        if (asset_class === "future") {
          Object.assign(
            state.stream.positions,
            Object.keys(data).reduce((res, symbol) => {
              const {
                balance,
                price,
                entry_price,
                liquidation_price,
                notional,
                unrealized_pnl,
                leverage,
                margin_mode,
              } = data[symbol];

              const id = getPortfolioAssetMsgId(exchange, _subaccount, asset_class, symbol);
              const oldSymbol = state.stream.positions[id];
              return {
                ...res,
                [id]: {
                  ...oldSymbol,
                  symbol,
                  exchange,
                  asset_class,
                  subaccount: _subaccount,
                  balance: balance ?? oldSymbol?.balance,
                  price: price ?? oldSymbol?.price,
                  entryPrice: entry_price ?? oldSymbol?.entryPrice,
                  liquidationPrice: liquidation_price ?? oldSymbol?.liquidationPrice,
                  notional: notional ?? oldSymbol?.notional,
                  unrealizedPnl: unrealized_pnl ?? oldSymbol?.unrealizedPnl,
                  leverage: leverage || 1,
                  margin_mode,
                },
              };
            }, {})
          );
        }
      }
    },
  },
});

export const {
  setActiveCefiAllocationItem,
  setActiveDefiAllocationItem,
  setSelectedExchange,
  setSelectedWallet,
  setCefiTotalBalance,
  setDefiTotalBalance,
  setAccountDefiAllocation,
  setAccountCefiAllocation,
  setTokenDefiAllocation,
  setTokenCefiAllocation,
  setDataUpdatedCefi,
  setDataUpdatedDefi,
  setWalletAllocation,
  setCefiExchangeMarketType,
  setCefiStreamData,
  setTableDataIsLoading,
  setExchangeIsLoading,
  setUserExchangesListLoading,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
