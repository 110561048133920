import { useNavigate } from "react-router-dom";
import { Stack, Typography, StackProps } from "@mui/material";

export const PaginationTooltip = ({ limit, ...props }: { limit: number } & StackProps) => {
  const navigate = useNavigate();

  const onClick = () => navigate("/settings/transactions-history");

  return (
    <Stack direction="row" gap={0.5} {...props} alignItems="center">
      <Typography variant="body2" fontSize="small" color="text.disabled">
        Showing only the latest {limit} records. For complete history, please go to{" "}
        <u style={{ cursor: "pointer" }} onClick={onClick}>
          transactions history
        </u>
      </Typography>
    </Stack>
  );
};
