import { CefiExchangeId, CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";

export type Msg = Record<string, any>;

export type Handler = (data: any, sourceData?: any) => void;

export type Listener = {
  handlers: Handler[];
  lastUpdate: number;
};

export enum MarketDataSubscriptionType {
  ORDERBOOK = "orderbook",
  OTC_ORDERBOOK = "otc_orderbook",
  TICKER = "ticker",
  TRADES = "trades",
  OHLCV = "ohlcv",
  POSITION = "position",
}

export type MarketDataSubscribeData = {
  data_type: MarketDataSubscriptionType;
  exchange: string;
  market_type: CefiExchangeMarketType;
  params: Record<string, string | number>;
  symbol: string;
};

export type MarketDataOHLCVDataItem = number[];

export type MarketDataOHLCVData = MarketDataOHLCVDataItem[];

export type MarketDataTradesDataItem = {
  info: {
    instId: "BTC-USDT";
    tradeId: "431805928";
    px: "29431.9";
    sz: "0.00217451";
    side: "buy";
    ts: "1691504600173";
  };
  timestamp: 1691504600173;
  datetime: "2023-08-08T14:23:20.173Z";
  symbol: "BTC/USDT";
  id: "431805928";
  order: null;
  type: null;
  takerOrMaker: null;
  side: "buy";
  price: 29431.9;
  amount: 0.00217451;
  cost: 63.999960869;
  fee: null;
  fees: [];
};

export type MarketDataTradesData = MarketDataTradesDataItem[];

export type MarketDataTickerData = {
  symbol: string;
  timestamp: number;
  datetime: string; // "2023-08-03T08:23:03.807Z"
  high: number;
  low: number;
  bid: number;
  bidVolume: number;
  ask: number;
  askVolume: number;
  vwap: number;
  open: number;
  close: number;
  last: number;
  previousClose: number | null;
  change: number;
  percentage: number;
  average: number;
  baseVolume: number;
  quoteVolume: number;
  info: {
    price?: number;
    instType: CefiExchangeMarketType;
    instId: string;
    last: number;
    lastSz: number;
    askPx: number;
    askSz: number;
    bidPx: number;
    bidSz: number;
    open24h: number;
    high24h: number;
    low24h: number;
    sodUtc0: number;
    sodUtc8: number;
    volCcy24h: number;
    vol24h: number;
    ts: number;
  };
};

export type MarketOtcOrderbookData = {
  account: {
    exchange_id: number;
    account_id: number;
    subaccount: "SPOT" | "CFT";
  };
  symbol: string;
  // hack real value is an number
  asset_class: CefiExchangeMarketType;
  bids: number[][];
  asks: number[][];
  timestamp: number;
};

export type MarketDataMessage = {
  event: "market_data";
  data_type: MarketDataSubscriptionType;
  symbol: string;
  market_type: CefiExchangeMarketType;
  exchange: string;
  params: Record<string, any>;
  data: any;
};

export type GenericMarketDataMessage<T> = Omit<MarketDataMessage, "data"> & {
  data: T;
};

export type MarketDataSubscribeMessage = {
  data_type: MarketDataSubscriptionType;
  exchange: string;
  market_type: CefiExchangeMarketType;
  params?: Record<string, string | number>;
  symbol: string;
};

export type MarketDataTickerMessage = GenericMarketDataMessage<MarketDataTickerData>;
export type MarketOtcOrderbookMessage = GenericMarketDataMessage<MarketOtcOrderbookData>;

export type CefiAccountData = {
  client_order_id: "212190712360960001";
  order_id: "212190712360960000";
  exchange_id: 1;
  symbol: "BTC/USDT";
  asset_class: CefiExchangeMarketType;
  side: "buy";
  quantity: 0.00066;
  strategy: "twap";
  created_at: 1691585408.13;
  start_time: 1691585407.425;
  clip_size_type: "automatic";
  subaccount: null;
  params: {
    duration_seconds: 60;
    trading_style: "hybrid";
    randomize_amount: true;
    extend_duration: false;
    placement_infos: {
      placement_mode: "default";
      placement: null;
      cancel: null;
    };
  };
  clip_size_val: null;
  limit_price: null;
  end_time: 1707483007.425;
  send_email_notification: true;
  exchange: "Binance";
  is_defi: false;
};

export type CefiAccountMessage = {
  message: {
    code: 1;
    data: CefiAccountData;
  };
};

export type PortfolioMsgAsset = {
  balance: number;
  price: number;
  entry_price: number;
  liquidation_price: number;
  notional: number;
  unrealized_pnl: number;
  leverage: number;
  margin_mode: string;
  is_position: boolean;
};

export type PortfolioMsgData = {
  asset_class: CefiExchangeMarketType;
  data: Record<string, PortfolioMsgAsset>;
  exchange: string;
  subaccount: string | null;
  is_position?: boolean;
};

export type PortfolioInitialMsgData = {
  account_id: number | null;
  balance: number;
  batch_id: number;
  entry_price: number;
  exchange_id: CefiExchangeId;
  is_position: boolean;
  liquidation_price: number | null;
  market_type: number;
  notional: number;
  price: number;
  subaccount: string | null;
  token_name: string;
  ts: string;
  unrealized_pnl: number;
  value: number;
  leverage: number;
  margin_mode: string;
};

export type PortfolioMessage =
  | {
      message: PortfolioMsgData;
    }
  | {
      init_data: PortfolioInitialMsgData[];
    };
